import { Http } from '@angular/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiErrorHandler } from 'tridion.web.ui/src/app/angular/api/error/ApiErrorHandler';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { BaseAuthorizedApi } from 'tridion.web.ui/src/app/angular/api/BaseAuthorizedApi';
import { ENVIRONMENT } from 'tridion.web.ui/src/app/angular/environment/ENVIRONMENT';
import { IEnvironment } from '../../environment/IEnvironment';
import { IProductSheet } from '../../model/productImporter/IProductSheet';

@Injectable()
export class ProductImporterProductSheetApi extends BaseAuthorizedApi<IEnvironment> {
    constructor(
        apiErrorHandler: ApiErrorHandler,
        authService: AuthService,
        @Inject(ENVIRONMENT) environment: IEnvironment,
        http: Http
    ) {
        super(apiErrorHandler, authService, environment, http);
    }

    getItems(siteId: string) {
        return this.getJson('productsheet/site/' + siteId);
    }

    inactivate(item: IProductSheet): Observable<any> {
        return this.delete('productsheet/' + item.productSheetRecordId);
    }

    importData(file: any, siteId: string): Observable<any> {
        return this.postJson('productsheet/site/' + siteId + '/upload', file);
    }

    download(item: IProductSheet) {
        return this.get('productsheet/' + item.productSheetRecordId + '/download');
    }

    protected getBaseUrl() {
        return this.environment.api.productImporter;
    }
}
