import { Variant } from '../femcare/Variant';

export enum Rendition {
    Femcare305x305 = 'Femcare305x305',
    Femcare615x615 = 'Femcare615x615',
    Femcare1500x750 = 'Femcare1500x750',
    H264_4500kbps_AAC_und_ch2_128kbps = 'H264_4500kbps_AAC_und_ch2_128kbps',
    H264_Broadband_720p = 'H264 Broadband 720p'
}

// Mapping object to associate some Variants with their corresponding Renditions
const VariantToRenditionMap: Partial<{ [key in Variant]: Rendition }> = {
    [Variant.FemC_01_305x305]: Rendition.Femcare305x305,
    [Variant.FemC_03_615x615]: Rendition.Femcare615x615,
    [Variant.FemC_08_1500x750]: Rendition.Femcare1500x750,
    [Variant.H264_4500kbps_AAC_und_ch2_128kbps]: Rendition.H264_4500kbps_AAC_und_ch2_128kbps,
    [Variant.H264_Broadband_720p]: Rendition.H264_Broadband_720p,
    // You can include only the mappings you need here
};

export class RenditionHelper {
    static getRenditionFromVariant(variantString: string): Rendition | undefined {
        const variantWithoutSuffix = variantString.replace(/_(png|jpg)$/, ''); // Removes _png or _jpg at the end of the string
        const variant: Variant | undefined = Variant[variantWithoutSuffix as keyof typeof Variant];
        if (variant && VariantToRenditionMap[variant]) {
            return VariantToRenditionMap[variant];
        }

        return undefined;
    }
}