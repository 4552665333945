import { IFemcareProduct } from '../model/product/femcare/IFemcareProduct';
import { sortBy } from 'lodash';
import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { GuidCrud } from 'tridion.web.ui/src/app/angular/crud/GuidCrud';
import { ProductImporterFormApi } from '../api/productImporter/ProductImporterFormApi';
import { ShowMoreAdminCrudList } from '../crud/list/ShowMoreAdminCrudList';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';
import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { AdminTitle } from '../layout/AdminTitle';
import { SiteApi } from '../api/auth/SiteApi';
import { Observable, of, Subscription, } from 'rxjs';
import { ISite } from '../model/site/ISite';
import CrudListFilter from '../crud/list/CrudListFilter';
import { Variant } from '../model/celum/femcare/Variant';
import { CelumApi } from '../api/celum/CelumApi';
import { IVariantCDNUrl } from '../model/celum/IVariantCDNUrl';
import { IFemcareMediaAssetRenditionUrl } from '../model/product/femcare/IFemcareMediaAssetRenditionUrl';
import { IFemcareLongDescriptionImage } from '../model/product/femcare/IFemcareLongDescriptionImage';
import { BaseCrud } from 'tridion.web.ui/src/app/angular/crud/BaseCrud';
import { RenditionHelper } from '../model/celum/femcare/Rendition';
import { IFemcareMediaAsset } from '../model/product/femcare/IFemcareMediaAsset';
import { UnderwearType } from '../model/product/femcare/UnderwearType';
import { UsageOccasion } from '../model/product/femcare/UsageOccasion';
import { ProductFeatures } from '../model/product/femcare/ProductFeatures';
import { IFemcareVideo } from '../model/product/femcare/IFemcareVideo';
import { map, shareReplay } from 'rxjs/operators';
import { FemcareSiteApi } from '../api/product/femcare/FemcareSiteApi';

type ProductCrud = BaseCrud<IFemcareProduct, ICrudData<IFemcareProduct>>;
@Component({
    templateUrl: 'ProductImporterForm.component.html',
    providers: [ProductImporterFormApi, SiteApi, FemcareSiteApi]
})
export class ProductImporterFormComponent
    extends ShowMoreAdminCrudList<IFemcareProduct, ICrudData<IFemcareProduct>>
    implements OnInit {
    sites: Observable<ISite[]>;
    url: string;
    ckeConfig: any;
    tempImageCelumId: string;
    underwearTypes$: any[];
    usageOccasions$: any[];
    productFeatures$: any[];
    tempImageUrlArray: any = [];
    @Input()
    selectedSiteId: string;
    isSyncSuccessful: boolean;
    enableSync: boolean;
    private readonly filter = new CrudListFilter<{ siteId: string }>(this);
    constructor(
        private adminTitle: AdminTitle,
        elementRef: ElementRef,
        prettySubmitGuard: PrettySubmitGuard,
        protected productImporterFormApi: ProductImporterFormApi,
        private siteApi: SiteApi,
        private celumApi: CelumApi,
        private femcareSiteApi: FemcareSiteApi
    ) {
        super(elementRef, prettySubmitGuard, 'Product');
    }

    ngOnInit() {
        this.adminTitle.setTitle('Product Importer Form');
        this.selectedSiteId = undefined;
        this.productImporterFormApi.selectedSiteId = this.selectedSiteId;
        this.underwearTypes$ = Object.values(UnderwearType);
        this.usageOccasions$ = Object.values(UsageOccasion);
        this.productFeatures$ = Object.values(ProductFeatures);

        this.femcareSiteApi.getAll().subscribe(femcareSites => {
            // @ts-ignore @todo
            this.sites = this.siteApi
                .getSitesByBrand('femcare').pipe(
                    // @ts-ignore @todo
                    map(sites => sortBy(sites, 'name')),
                    shareReplay(),);
            this.sites.subscribe(sites => {
                const filteredSites = sites.filter(site =>
                    femcareSites.some(femcareSite => femcareSite.siteUrl === site.domainUrl)
                );
                this.sites = of(filteredSites);
            });
            // this.selectedSiteId = undefined;
        });

        this.initProducts();
        this.ckeConfig = {
            allowedContent: false,
            forcePasteAsPlainText: true,
            toolbarGroups: [
                {
                    name: 'productImporterForm',
                    groups: ['basicstyles', 'list', 'undo', 'clipboard', 'links']
                }
            ],
            versionCheck: false
        };
    }

    initProducts() {
        this.filter.observable.subscribe(filter => {
            let siteId = filter.siteId;
            if (siteId) {
                this.productImporterFormApi
                    .getProductsBySite(siteId)
                    .subscribe(products => {
                        products.forEach((p: IFemcareProduct) => {
                            this.initLongDescription(p);
                            this.initVideos(p);
                            this.initSecondaryImages(p);
                        });
                        this.init(sortBy(products, 'title'));
                    });
                this.selectedSiteId = siteId;
                this.productImporterFormApi.selectedSiteId = this.selectedSiteId;
            }
        });
    }

    initSecondaryImages(product: IFemcareProduct) {
        if (
            product.secondaryImages === undefined ||
            product.secondaryImages.length === 0
        ) {
            product.secondaryImages = [
                {
                    celumMediaAssetId: ''
                } as IFemcareMediaAsset
            ];
        }
    }

    initVideos(product: IFemcareProduct) {
        if (product.videos === undefined || product.videos.length === 0) {
            product.videos = [
                {
                    video: {
                        celumMediaAssetId: ''
                    } as IFemcareMediaAsset,
                    videoPoster: {
                        celumMediaAssetId: ''
                    } as IFemcareMediaAsset
                }
            ];
        }
        product.videos.forEach(v => {
            if (v.video === undefined) {
                v.video = {
                    celumMediaAssetId: ''
                } as IFemcareMediaAsset;
            }
            if (v.videoPoster === undefined) {
                v.videoPoster = {
                    celumMediaAssetId: ''
                } as IFemcareMediaAsset;
            }
        });
    }

    initLongDescription(product: IFemcareProduct) {
        if (
            product.longDescriptions === undefined ||
            product.longDescriptions.length === 0
        ) {
            product.longDescriptions = [
                {
                    longDescription: '',
                    image: {} as IFemcareMediaAsset
                }
            ] as IFemcareLongDescriptionImage[];
        }
    }

    updateFilter() {
        var filter = this.filter;
        if (!filter.setFilter({ siteId: this.selectedSiteId })) {
            setTimeout(() => {
                this.selectedSiteId = filter.getFilter().siteId;
            }, 0);
        }
    }

    onBlurMediaAsset(
        mainImageCelumId: string,
        productEan: string,
        elementType: string,
        crudIndex: number,
        subIndex: number
    ) {
        if (this.tempImageCelumId !== mainImageCelumId && mainImageCelumId !== '') {
            this.tempImageCelumId = mainImageCelumId;
            this.setRenditionUrlsPerType(
                mainImageCelumId,
                elementType,
                crudIndex,
                subIndex
            );
            let productNewImage = this.celumApi
                .getVariantUrls(mainImageCelumId).pipe(
                    map((itemUrls: IVariantCDNUrl[]) => {

                        return {
                            cdnUrl:
                                (
                                    itemUrls.find((i: any) =>
                                        i.Variant.includes(Variant.FemC_01_305x305)
                                    ) || ({} as IVariantCDNUrl)
                                ).CDNUrl || ''
                        };
                    })
                )
            productNewImage.subscribe((productNewImageItem: any) => {
                let product = this.cruds.find(p => p.data.item.ean === productEan);
                elementType += crudIndex + '' + subIndex + '-' + product.data.item.ean;
                var wrappedResult = document.getElementsByClassName(elementType);
                if (
                    wrappedResult !== undefined &&
                    wrappedResult.length >= 0 &&
                    wrappedResult[0] !== undefined
                ) {
                    wrappedResult[0].setAttribute('src', productNewImageItem.cdnUrl);
                    wrappedResult[0].removeAttribute('hidden');
                }
            });
        }
    }

    setRenditionUrlsPerType(
        mainImageCelumId: string,
        elementType: string,
        crudIndex: number,
        subIndex: number
    ): void {
        this.celumApi
            .getVariantUrls(mainImageCelumId)
            .subscribe((itemUrls: IVariantCDNUrl[]) => {
                let renditionUrls: IFemcareMediaAssetRenditionUrl[] = [];
                itemUrls.forEach((variantUrlItem: IVariantCDNUrl) => {
                    const rendition = RenditionHelper.getRenditionFromVariant(variantUrlItem.Variant);
                    if (rendition) {
                        let result: IFemcareMediaAssetRenditionUrl = {
                            rendition: rendition,
                            cdnUrl: variantUrlItem.CDNUrl
                        };
                        renditionUrls.push(result);
                    }
                });

                switch (elementType) {
                    case 'heroImage':
                        this.cruds[crudIndex].data.item.heroImage.renditionUrls = renditionUrls;
                        break;
                    case 'secondaryImages':
                        this.cruds[crudIndex].data.item.secondaryImages[subIndex].renditionUrls = renditionUrls;
                        break;
                    case 'videoPoster':
                        this.cruds[crudIndex].data.item.videos[subIndex].videoPoster.renditionUrls = renditionUrls;
                        break;
                    case 'video':
                        this.cruds[crudIndex].data.item.videos[subIndex].video.renditionUrls = renditionUrls;
                        break;
                    case 'longDescriptions':
                        this.cruds[crudIndex].data.item.longDescriptions[subIndex].image.renditionUrls = renditionUrls;
                }
            });
    }

    addLongDescription(crud: ProductCrud) {
        crud.data.item.longDescriptions.push({
            longDescription: '',
            image: { celumMediaAssetId: '' } as IFemcareMediaAsset
        });
    }

    addVideoSection(crud: ProductCrud) {
        crud.data.item.videos.push({
            video: { celumMediaAssetId: '' } as IFemcareMediaAsset,
            videoPoster: { celumMediaAssetId: '' } as IFemcareMediaAsset
        });
    }

    addAdditionalImageSection(crud: ProductCrud) {
        crud.data.item.secondaryImages.push({
            celumMediaAssetId: ''
        } as IFemcareMediaAsset);
    }

    removeLongDescription(crud: ProductCrud, index: number) {
        crud.data.item.longDescriptions.splice(index, 1);
    }

    removeVideoSection(crud: ProductCrud, index: number) {
        crud.data.item.videos.splice(index, 1);
    }

    removeAdditionalImageSection(crud: ProductCrud, index: number) {
        crud.data.item.secondaryImages.splice(index, 1);
    }

    syncToRedis() {
        this.productImporterFormApi.syncToRedis(this.selectedSiteId)
            .subscribe((result: any) => {
                this.isSyncSuccessful = result.isSuccessful;
            });
    }

    confirmSync() {
        this.enableSync = true;
    }

    cancelSync() {
        this.enableSync = false;
        this.isSyncSuccessful = false;
    }

    getSyncClass() {
        let classValue: string;
        if (this.isSyncSuccessful) {
            classValue = 'btn btn-success mr-card';
        } else {
            classValue = 'btn btn-warning mr-card';
        }

        return classValue;
    }

    addTagFn(name) {
        return name;
    }

    protected createCrud() {

        return new GuidCrud(this.productImporterFormApi);
    }

    protected createItem() {

        return {
            heroImage: {} as IFemcareMediaAsset,
            secondaryImages: [
                {
                    celumMediaAssetId: ''
                } as IFemcareMediaAsset
            ] as IFemcareMediaAsset[],
            longDescriptions: [
                { longDescription: '', image: { celumMediaAssetId: '' } as IFemcareMediaAsset }
            ] as IFemcareLongDescriptionImage[],
            videos: [
                {
                    video: {
                        celumMediaAssetId: ''
                    } as IFemcareMediaAsset,
                    videoPoster: {
                        celumMediaAssetId: ''
                    } as IFemcareMediaAsset
                }
            ] as IFemcareVideo[]
        } as IFemcareProduct;
    }
}