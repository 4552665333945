import { ICatalog } from '../model/product/ICatalog';

export default function getCatalogName(catalog: ICatalog) {
    if (catalog) {
        const isHMS = ['Tena', 'Leukoplast', 'Cutimed'].includes(catalog.brand);
        const baseName = `${catalog.countryName}: ${catalog.languageName}`;

        if (isHMS) {
            const code = catalog.gpimCatalogCode || catalog.catalogCode;

            return `${baseName} (${code})`;
        }

        return baseName;
    }
}
