import 'core-js/modules/es6.string.starts-with';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { map, shareReplay } from 'rxjs/operators';
import { Observable ,  Subscription } from 'rxjs';

import { AdminTitle } from '../layout/AdminTitle';
import { Brand } from '../model/cache/Brand';
import { LiveStageService } from '../environment/LiveStage.service';
import { RedisCacheApi } from '../api/cache/RedisCacheApi';

@Component({
    templateUrl: 'Redis.component.html',
    providers: [RedisCacheApi]
})
export class RedisComponent implements OnDestroy, OnInit {
    private static SpacesPerTab = 4;
    brand?: Brand;
    tag?: string;
    tags?: Observable<string[]>;
    key?: string;
    keys?: Observable<string[]>;
    value?: Observable<string>;
    readonly brands = Object.keys(Brand);
    private liveStageSubscription: Subscription;

    constructor(
        private adminTitle: AdminTitle,
        private liveStageService: LiveStageService,
        private redisCacheApi: RedisCacheApi
    ) {}

    ngOnInit() {
        this.adminTitle.setTitle('Redis Cache');

        this.liveStageService.addListener(this, 'redis');
        this.liveStageSubscription = this.liveStageService.liveStage.subscribe(() => {
            this.loadTags();
        });
    }

    ngOnDestroy() {
        this.liveStageService.removeListener(this);
        this.liveStageSubscription.unsubscribe();
    }

    loadTags() {
        this.tag = undefined;

        var brand = this.brand;

        if (brand) {
            this.tags = this.redisCacheApi
                .getTags(brand).pipe(
                map(tags => {
                    return (tags || []).sort();
                }),
                shareReplay(),);
        }
    }

    loadKeys() {
        this.key = undefined;

        var brand = this.brand,
            tag = this.tag;

        if (brand && tag) {
            this.keys = this.redisCacheApi
                .getKeys(brand, tag).pipe(
                map(keys => {
                    return keys.sort();
                }),
                shareReplay(),);
        }
    }

    loadValue() {
        this.value = undefined;

        var brand = this.brand,
            tag = this.tag,
            key = this.key;

        if (brand && tag && key) {
            this.value = this.redisCacheApi
                .getValue(brand, tag, key).pipe(
                map(value => {
                    try {
                        value = JSON.stringify(
                            JSON.parse(value),
                            undefined,
                            RedisComponent.SpacesPerTab
                        );
                    } catch (e) {
                        // Do nothing.
                    }

                    return value;
                }),
                shareReplay(),);
        }
    }

    copyToClipboard = async () => {
        try {
            const element = document.querySelector('.user-select-all');
            await navigator.clipboard.writeText(element.textContent);
        } catch (error) {
            // Do nothing.
        }
    };
}
