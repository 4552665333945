export enum Variant {
    AzureJPG4K = 'AzureJPG4K',
    AzurePNG4K = 'AzurePNG4K',
    brnB_01_2365x2365_tif = 'brnB_01_2365x2365_tif',
    CRMpng_300dpi = 'CRMpng_300dpi',
    CRMpng_300dpi_900px = 'CRMpng_300dpi_900px',
    FemC_01_305x305 = 'FemC_01_305x305',
    FemC_02_390x390 = 'FemC_02_390x390',
    FemC_03_615x615 = 'FemC_03_615x615',
    FemC_04_222x148 = 'FemC_04_222x148',
    FemC_05_350x210 = 'FemC_05_350x210',
    FemC_06_600x360 = 'FemC_06_600x360',
    FemC_07_860x516 = 'FemC_07_860x516',
    FemC_08_1500x750 = 'FemC_08_1500x750',
    FemC_09_1230x410 = 'FemC_09_1230x410',
    FemC_10_1500x500 = 'FemC_10_1500x500',
    FemC_11_750x375 = 'FemC_11_750x375',
    FemC_12_750x250 = 'FemC_12_750x250',
    FemC_13_125x125 = 'FemC_13_125x125',
    FemC_14_1230x615 = 'FemC_14_1230x615',
    FemC_15_1500x600 = 'FemC_15_1500x600',
    FemC_16_305x305 = 'FemC_16_305x305',
    FemC_17_390x390 = 'FemC_17_390x390',
    FemC_18_615x615 = 'FemC_18_615x615',
    FemC_19_222x148 = 'FemC_19_222x148',
    FemC_20_600x360 = 'FemC_20_600x360',
    French_WebImages_1200x860 = 'French_WebImages_1200x860',
    GS1_Nordics_clipping_Path_tif = 'GS1_Nordics_clipping_Path_tif',
    Hygi_06_300x300 = 'Hygi_06_300x300',
    original = 'original',
    Preview = 'Preview',
    Thumbnail = 'Thumbnail',
    H264_Broadband_720p = 'H264 Broadband 720p',
    H264_4500kbps_AAC_und_ch2_128kbps = 'H264_4500kbps_AAC_und_ch2_128kbps'
}